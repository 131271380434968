import { Heading1 } from "@elements/typography"
import React, { useEffect } from "react"
import Seo from "@root/seo"
import WpCasesArchive from "@modules/WpCasesArchive"
import WpAcfVideoModuleBlock from "@modules/WpAcfVideoModuleBlock"
import tw, { styled } from "twin.macro"
import { useStateContext } from "@context/stateContext"
import { motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"

const Header = styled(motion.section)`
  ${tw`flex items-center justify-center w-full p-12 pt-32 text-center lg:pt-64`}
`

const CasesPage = () => {
  const stateContext = useStateContext()

  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 53 }) {
        id
        blocks {
          __typename
          ... on WpAcfVideoModuleBlock {
            acfBlockVideoModule {
              file
              videoEmbed
              videoFile {
                link
                uri
                title
              }
              thumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  useEffect(() => {
    stateContext.dispatch({ action: "SET_MENUE_LIGHT" })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Seo title="Work" />

      <Header
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
      >
        <Heading1>Cases</Heading1>
      </Header>
      {data.wpPage?.blocks[0]?.acfBlockVideoModule && <WpAcfVideoModuleBlock moduleData={data.wpPage?.blocks[0]?.acfBlockVideoModule} />}
      <motion.div initial={{ y: 30, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5, ease: [0.17, -0.05, 0.26, 0.67] }}>
        <WpCasesArchive />
      </motion.div>
    </>
  )
}

export default CasesPage
